import { FlexBox } from '@rario/shared-components'
import {
  // WelcomeStateEnum,
  WidgetIdsEnum,
} from 'interfaces/homePage'
import MarketplaceWidget from './MarketplaceWidget'
// import PacksWidget from './PacksWidget'
// import PicksWidget from './PicksWidget'
// import { useCustomUser } from 'contexts/CustomUserProvider'
// import BuyPackBanner from 'components/PackBox/BuyPackBanner'
// import { getWelcomeState } from 'utils/utils'
// import { FullWidth } from 'components/Layout/Layout.styles'

const HomePageWidgets: React.FunctionComponent<{}> = () => {
  // const { user } = useCustomUser()
  // const welcomeState = getWelcomeState(user)
  // const showBuyPackBanner =
  //   welcomeState === WelcomeStateEnum.NO_PURCHASE || welcomeState === WelcomeStateEnum.NEW_USER
  return (
    <FlexBox width="100%" flexDirection={'column'}>
      <FlexBox mb="24px" overflow={'hidden'} id={WidgetIdsEnum.MARKETPLACE_WIDGET}>
        <MarketplaceWidget />
      </FlexBox>

      {/* <FullWidth>
        {showBuyPackBanner ? (
          <FlexBox mx={'auto'} width="100%" justifyContent={'center'}>
            <BuyPackBanner />
          </FlexBox>
        ) : (
          <></>
        )}
      </FullWidth> */}
      {/* 
      <FlexBox flexDirection="column" id={WidgetIdsEnum.PACKS_WIDGET}>
        <PacksWidget />
      </FlexBox>

      <FlexBox overflow={'hidden'} id={WidgetIdsEnum.PICKS_WIDGET}>
        <PicksWidget />
      </FlexBox> */}
    </FlexBox>
  )
}

export default HomePageWidgets
